import "./productCard.css";
import Carousel from "../Carousel";

function ProductCard(props) {
  const {
    prod_name,
    prod_short_desc,
    id,
    prod_long_desc,
    prod_categ,
    primary_image,
    product_images,
  } = props.product;

  const carouselSlides = product_images.map((img) => {
    return {
      backgroundUrl: img,
    };
  });

  function dowloadfile(file) {
    const link = document.createElement("a");
    link.download = file.name;
    link.target = "_blank";
    link.href = file.link;
    link.click();
  }

  return (
    <section>
      <div>
        <div className="row justify-content-center">
          <div>
            <div
              style={{ width: 400 }}
              className="card text-black d-flex justify-content-center align-items-center"
            >
              <div
                style={{
                  marginTop: 20,
                  width: 300,
                  height: 200,
                  background: `url(${primary_image}) center/cover no-repeat`,
                }}
              ></div>
              <div className="card-body">
                <div className="">
                  <h5 className="card-title" style={{ fontWeight: 700 }}>
                    {prod_name}
                  </h5>
                </div>
                <div>
                  <div
                    style={{ height: 150, color: "#6b6363" }}
                    className="d-flex justify-content-between overflow-auto"
                  >
                    <span>{prod_short_desc}</span>
                  </div>
                </div>
                <div className="d-flex justify-content-between total font-weight-bold mt-4">
                  <button
                    type="button"
                    className="btn btn-primary"
                    data-bs-toggle="modal"
                    data-bs-target={`#${id}`}
                  >
                    See Details
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div
        className="modal fade"
        id={id}
        tabIndex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-xl">
          <div className="modal-content ">
            <div className="modal-header">
              <h5 className="modal-title" id="exampleModalLabel">
                {prod_name}
              </h5>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <Carousel slides={carouselSlides} />
            <div className="p-4">
              {prod_long_desc}
              <table className="table my-4 ">
                <thead>
                  <tr className="table-active">
                    {prod_categ.map((cat, index) => (
                      <th key={index} scope="col">
                        {cat.categ_name || "N/A"}
                      </th>
                    ))}
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    {prod_categ.map((cat, index) => (
                      <th scope="row" key={index}>
                        {cat.categ_items.map((item, index) => (
                          <div
                            key={index}
                            className="item-row d-flex justify-content-between align-items-center gap-1"
                          >
                            {item.name}{" "}
                            <button
                              className="item-download-btn btn btn-success"
                              onClick={() => dowloadfile(item)}
                            >
                              Download
                            </button>
                          </div>
                        ))}
                      </th>
                    ))}
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default ProductCard;
