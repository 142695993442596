import "./header.css";
import logo from "../../assets/images/UniversalTechSolutions-Logo.png";

function Header() {
  return (
    <header>
      <nav
        style={{
          borderRadius: "0 0 5px 5px",
          boxShadow: "0px 2px 7px 0px #777777",
        }}
        className="navbar navbar-expand-lg navbar-light bg-light fixed-top"
      >
        <div className="container">
          <a
            className="navbar-brand logotext d-flex align-items-center"
            href="/"
          >
            <img className="logo" src={logo} alt="logo" />
            UniverselTechsolutions
          </a>
          <button
            className="navbar-toggler"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarResponsive"
            aria-controls="navbarResponsive"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span className="navbar-toggler-icon"></span>
          </button>
          <div className="collapse navbar-collapse" id="navbarResponsive">
            <ul className="navbar-nav ms-auto">
              <li className="nav-item active">
                <a className="nav-link" href="#aboutus">
                  About Us
                </a>
              </li>
              <li className="nav-item">
                <a className="nav-link" href="#products">
                  Products
                </a>
              </li>
              <li className="nav-item">
                <a className="nav-link" href="#ourpartners">
                  Our Partners
                </a>
              </li>
              <li className="nav-item">
                <a className="btn btn-primary" href="#contactus">
                  Contact Us
                </a>
              </li>
            </ul>
          </div>
        </div>
      </nav>
    </header>
  );
}

export default Header;
