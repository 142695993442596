import 'bootstrap/dist/css/bootstrap.min.css';
import "bootstrap/dist/js/bootstrap";
import './App.css';

import Footer from './components/Footer';
import Header from './components/Header';
import Carousel from './components/Carousel';

import aboutus from "./assets/images/aboutus.svg"
import aboutus2 from "./assets/images/5973007_21952.jpg"
import ProductCard from './components/ProductCard';

import productsData from "./assets/products.json"
import Contact from './components/Contact';
import Partners from './components/Partners';

function App() {

  const carouselSlides = [{
    backgroundUrl: '/images/Banner/banner1.jpg',
    foreground: <div>
      <h2>Powering innovation & customization</h2>
      <h6> we support , upgrade and provide solutions</h6>
    </div>
  }, {
    backgroundUrl: '/images/Banner/banner2.jpg',
    // foreground: <div>
    //   <h5>Second slide label</h5>
    //   <p>Some representative placeholder content for the second slide.</p>
    // </div>
  }, {
    backgroundUrl: '/images/Banner/banner3.jpg',
    // foreground: <div>
    //   <h5>Third slide label</h5>
    //   <p>Some representative placeholder content for the third slide.</p>
    // </div>
  }, {
    backgroundUrl: '/images/Banner/banner4.jpg',
    // foreground: <div>
    //   <h5>First slide label</h5>
    //   <p>Some representative placeholder content for the first slide.</p>
    // </div>
  }, {
    backgroundUrl: '/images/Banner/banner5.jpg',
    // foreground: <div>
    //   <h5>First slide label</h5>
    //   <p>Some representative placeholder content for the first slide.</p>
    // </div>
  },
  {
    backgroundUrl: '/images/Banner/banner6.jpg',
    // foreground: <div>
    //   <h5>First slide label</h5>
    //   <p>Some representative placeholder content for the first slide.</p>
    // </div>
  },
    //  {
    //   backgroundUrl: '/images/Banner/banner7.jpg',
    //   // foreground: <div>
    //   //   <h5>First slide label</h5>
    //   //   <p>Some representative placeholder content for the first slide.</p>
    //   // </div>
    // }, {
    //   backgroundUrl: '/images/Banner/banner8.jpg',
    //   // foreground: <div>
    //   //   <h5>First slide label</h5>
    //   //   <p>Some representative placeholder content for the first slide.</p>
    //   // </div>
    // }, {
    //   backgroundUrl: '/images/Banner/banner9.jpg',
    //   // foreground: <div>
    //   //   <h5>First slide label</h5>
    //   //   <p>Some representative placeholder content for the first slide.</p>
    //   // </div>
    // }

  ]


  return (
    <div className="App">
      <Header />
      <Carousel slides={carouselSlides} />
      <section id='aboutus' className="about section py-5">
        <div className="container">
          <h1 className='fw-bold d-flex justify-content-center'>About Us</h1>
          <div className="row align-items-center">
            <div className="col-lg-6 mt-5 mt-lg-0">
              <h3 className='fw-bold'>Connecting you to the world of military grade electronics.</h3>
              <p className='aboutus-description'>At UniverselTechSolutions, we provide advanced rugged displays and mobile computers for various sectors like military, law enforcement, oil & gas, automotive, aviation, survey, and field service. Our skilled staff specializes in ruggedizing computer equipment to meet MIL-STD-810G specifications, ensuring durability in harsh environments.</p>
            </div>
            <div className="col-lg-6">
              <img className="img-fluid" src={aboutus2} alt='' />
            </div>
          </div>
          <div className="row align-items-center">
            <div className="col-lg-6">
              <div className="about-img">
                <img className="img-fluid" src={aboutus} alt='' />
              </div>
            </div>
            <div className="col-lg-6 mt-5 mt-lg-0">
              <div className="pl-0 pl-lg-4">
                <h3 className="fw-bold">Unleash the Power: Command, Dominate, Conquer!</h3>
                <p className='aboutus-description'>we are committed to providing cutting-edge military-grade computers and electronic accessories that are built to withstand the rigours of current defence operations. Our organisation is devoted to offering cutting-edge technological solutions that enable our clients to excel in their objectives, based on a thorough grasp of the special requirements of the military and defence industries.</p>
                <p className='aboutus-description'>Our product offerings include military-grade PCs, ruggedized screens, secure communication systems, specialized peripherals, and innovative electronic accessories. Our products are precisely designed and engineered to resist extreme circumstances, assuring excellent performance and dependability even in the hardest situations.</p>
                <p className='aboutus-description'>Our persistent dedication to quality and adherence to the highest industry standards distinguishes us. Our professional staff combines their knowledge with the most recent technological advances to create products that meet or surpass the demanding MIL-STD criteria.</p>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section id='products' className='product-section d-flex flex-column justify-content-center p-4'>
        <div className='container d-flex flex-column justify-content-center'>
          <h1 className='py-4 fw-bold d-flex justify-content-center'>Our Products</h1>
          <div className='d-flex flex-wrap gap-2 justify-content-center'>
            {productsData.map((product, index) => <ProductCard key={index} product={product} />)}
          </div>
        </div>
      </section>
      <section id='ourpartners' className='partners-section d-flex flex-column justify-content-center p-4'>
        <h1 className='py-2 fw-bold d-flex justify-content-center'>Authorized Distributors</h1>
        <Partners />
      </section>
      <section id='contactus' className='contactus-section d-flex flex-column justify-content-center p-4'>
        <h1 className='py-4 fw-bold d-flex justify-content-center'>Contact Us</h1>
        <Contact />
      </section>

      <Footer />

    </div>
  );
}

export default App;
