import "./carousel.css";
import uuid from 'react-uuid';

function Carousel(props) {
  const { slides } = props;
  const carouselId = uuid();

  return <div id={carouselId} className="carousel slide" data-bs-ride="carousel">
    <div className="carousel-indicators">
      {slides && slides.map((slide, index) => <button key={index} type="button" data-bs-target={`#${carouselId}`} data-bs-slide-to={index} className={`${index === 0 ? "active" : ""}`} aria-current={index === 0 ? true : false} aria-label={`Slide ${index + 1}`}></button>)}
    </div>
    <div className="carousel-inner">
      {slides && Array.isArray(slides) && slides.map((slide, index) => <div key={index} className={`carousel-item ${index === 0 ? "active" : ""}`} style={{ backgroundColor: "gray", backgroundImage: `url(${slide.backgroundUrl})` }}>
        <div className="carousel-caption">
          {slide.foreground}
        </div>
      </div>)}
    </div>
    <button className="carousel-control-prev" type="button" data-bs-target={`#${carouselId}`} data-bs-slide="prev">
      <span className="carousel-control-prev-icon" aria-hidden="true"></span>
      <span className="visually-hidden">Previous</span>
    </button>
    <button className="carousel-control-next" type="button" data-bs-target={`#${carouselId}`} data-bs-slide="next">
      <span className="carousel-control-next-icon" aria-hidden="true"></span>
      <span className="visually-hidden">Next</span>
    </button>
  </div>;
}

export default Carousel;