import "./footer.css";
import logo from "../../assets/images/UniversalTechSolutions-Logo.png";

function Footer() {
  return (
    <div>
      <footer style={{ backgroundColor: "#1c2331" }}>
        <div className="text-center text-lg-start text-white">
          {/* Uncomment below code to use social media links */}
          <section
            className="d-flex justify-content-between p-4"
            style={{ backgroundColor: "#6351ce" }}
          >
            <div className="me-5">
              <span>Get connected with us on social networks:</span>
            </div>
            <div>
              <a href="#!" className="text-white me-4">
                <i className="fab fa-facebook-f"></i>
              </a>
              <a href="#!" className="text-white me-4">
                <i className="fab fa-twitter"></i>
              </a>
              <a href="#!" className="text-white me-4">
                <i className="fab fa-google"></i>
              </a>
              <a href="#!" className="text-white me-4">
                <i className="fab fa-instagram"></i>
              </a>
              <a href="#!" className="text-white me-4">
                <i className="fab fa-linkedin"></i>
              </a>
              <a href="#!" className="text-white me-4">
                <i className="fab fa-github"></i>
              </a>
            </div>
          </section>

          <section className="p-4">
            <div className="container text-center text-md-start mt-5">
              <div className="row mt-3">
                <div className="col-md-3 col-lg-4 col-xl-3 mx-auto mb-4">
                  <h6
                    style={{ marginTop: -8 }}
                    className=" text-uppercase d-flex flex-column justify-content-center align-items-center fw-bold"
                  >
                    <img style={{ width: 200 }} src={logo} alt="logo" />
                    <p style={{ fontSize: "1.4rem", textAlign: "center" }}>
                      Universel TechSolutions
                    </p>
                  </h6>
                </div>

                <div className="col-md-2 col-lg-2 col-xl-2 mx-auto mb-4">
                  <h6 className="text-uppercase fw-bold">Company</h6>
                  <hr
                    className="mb-4 mt-0 d-inline-block mx-auto"
                    style={{ width: 60, backgroundColor: "#7c4dff", height: 2 }}
                  />
                  <p>
                    <a href="#!" className="text-white text-decoration-none">
                      <small>About UniverselTechSolutions</small>
                    </a>
                  </p>
                  <p>
                    <a href="#!" className="text-white text-decoration-none">
                      <small>For Business</small>
                    </a>
                  </p>
                  <p>
                    <a href="#!" className="text-white text-decoration-none">
                      <small>Our partners</small>
                    </a>
                  </p>
                  <p>
                    <a href="#!" className="text-white text-decoration-none">
                      <small>Careers</small>
                    </a>
                  </p>
                </div>

                <div className="col-md-3 col-lg-2 col-xl-2 mx-auto mb-4">
                  <h6 className="text-uppercase fw-bold">Resourses</h6>
                  <hr
                    className="mb-4 mt-0 d-inline-block mx-auto"
                    style={{ width: 60, backgroundColor: "#7c4dff", height: 2 }}
                  />
                  <p>
                    <a href="#!" className="text-white text-decoration-none">
                      <small>Help center</small>
                    </a>
                  </p>
                  <p>
                    <a href="#!" className="text-white text-decoration-none">
                      <small>Privacy & terms</small>
                    </a>
                  </p>
                  <p>
                    <a href="#!" className="text-white text-decoration-none">
                      <small>Shipping Rates</small>
                    </a>
                  </p>
                  <p>
                    <a href="#!" className="text-white text-decoration-none">
                      <small>Help</small>
                    </a>
                  </p>
                </div>

                <div className="col-md-4 col-lg-3 col-xl-3 mx-auto mb-md-0 mb-4">
                  <h6 className="text-uppercase fw-bold">Contact</h6>
                  <hr
                    className="mb-4 mt-0 d-inline-block mx-auto"
                    style={{ width: 60, backgroundColor: "#7c4dff", height: 2 }}
                  />
                  <p>
                    <i className="fas fa-home mr-3"></i> 77, Defense Colony,
                    Vinayak Nagar, Kattigenahalli, Bengaluru, Karnataka, India
                    560063
                  </p>
                  <p>
                    <i className="fas fa-envelope mr-3"></i>{" "}
                    utsinfo@universeltechsolutions.com
                  </p>
                  <p>
                    <i className="fas fa-phone mr-3"></i> +91 9740 206921
                  </p>
                  <p>
                    <i className="fas fa-phone mr-3"></i> +91 9555 751100
                  </p>
                </div>
              </div>
            </div>
          </section>

          <div
            className="text-center p-3"
            style={{ backgroundColor: "rgba(0, 0, 0, 0.2)" }}
          >
            © 2023 Copyright: &nbsp;
            <a
              className="text-white text-decoration-none"
              href="https://mdbootstrap.com/"
            >
              UniverselTechsolutions.com
            </a>
          </div>
        </div>
      </footer>
    </div>
  );
}

export default Footer;
