import "./partners.css";
import partner1 from "../../assets/images/partner1.jpg";
import partner2 from "../../assets/images/partner2.jpg";

function Partners() {
  return (
    <div>
      <div className="content">
        <div className="container">
          <div className="row">
            {/* card 1 */}
            <div className="col-md-6 col-lg-6 pb-3">
              <div className="card card-custom bg-white border-white border-0">
                <div
                  className="card-custom-img"
                  style={{
                    backgroundImage: `url(${partner1})`,
                  }}
                ></div>
                <div className="card-body" style={{ overflowY: "auto" }}>
                  <h4 className="card-title title">Shradhha Enterprises</h4>
                  <p className="card-text">
                    <p>
                      <i className="fa-solid fa-location-dot"></i> Sec-21,
                      Scheme 4, Plot 78, Yamunangar,Nigdi, Pune,
                      Maharashtra,India 411044
                    </p>
                    <p>
                      <i class="fas fa-envelope mr-3"></i>{" "}
                      d.roopkiran@shradhaent.com
                    </p>
                    <p>
                      <i className="fas fa-phone mr-3"></i> +91 9373465706
                    </p>
                    <p>
                      <i className="fas fa-phone mr-3"></i> +91 9373446570
                    </p>
                  </p>
                </div>
              </div>
            </div>

            {/* card 2 */}
            <div className="col-md-6 col-lg-6 pb-3">
              <div className="card card-custom bg-white border-white border-0">
                <div
                  className="card-custom-img"
                  style={{
                    backgroundImage: `url(${partner2})`,
                  }}
                ></div>
                <div className="card-body" style={{ overflowY: "auto" }}>
                  <h4 className="card-title title">Embeddedhive LLP</h4>
                  <p className="card-text">
                    <p>
                      <i className="fa-solid fa-location-dot"></i> B No. 47, T
                      no. 563, Ananad Nagar GHB Flats, Chandlodia Road, New
                      Wadaj,Ahmedabad,Gujarat- 380013
                    </p>
                    <p>
                      <i class="fas fa-envelope mr-3"></i> pmo@embeddedhive.com
                    </p>
                    <p>
                      <i className="fas fa-phone mr-3"></i> +91 8980022709
                    </p>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Partners;
