import "./contact.css";
import contactImage from "../../assets/images/contact.svg";

function Contact() {
  return (
    <div>
      <div className="content py-2">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-md-10">
              <div className="row justify-content-center">
                <h3 className="heading mb-4">Let's talk about everything!</h3>
                <div className="col-md-6">
                  <img src={contactImage} alt="contact" className="img-fluid" />
                </div>
                <div className="col-md-6">
                  <form
                    name="contact"
                    method="post"
                    className="mb-5"
                    id="contactForm"
                  >
                    <input type="hidden" name="form-name" value="contact" />
                    <div className="row">
                      <div className="col-md-12 form-group">
                        <input
                          type="text"
                          className="form-control control-h-50 error"
                          name="name"
                          id="name"
                          placeholder="Your name"
                          aria-required="true"
                          aria-invalid="true"
                        />
                        {/* <label id="name-error" className="error">
                          Please enter your name
                        </label> */}
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-12 form-group">
                        <input
                          type="text"
                          className="form-control control-h-50 error"
                          name="email"
                          id="email"
                          placeholder="Email"
                          aria-required="true"
                        />
                        {/* <label id="email-error" className="error">
                          Please enter a valid email address
                        </label> */}
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-12 form-group">
                        <input
                          type="text"
                          className="form-control control-h-50"
                          name="subject"
                          id="subject"
                          placeholder="Subject"
                        />
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-12 form-group">
                        <textarea
                          className="form-control error"
                          name="message"
                          id="message"
                          cols="30"
                          rows="7"
                          placeholder="Write your message"
                          aria-required="true"
                        ></textarea>
                        {/* <label id="message-error" className="error">
                          Please enter a message
                        </label> */}
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-12">
                        <input
                          type="submit"
                          value="Send Message"
                          className="btn btn-primary rounded-0 py-2 px-4"
                        />
                        {/* <span className="submitting"></span> */}
                      </div>
                    </div>
                  </form>
                  {/* <div id="form-message-warning mt-4"></div>
                  <div id="form-message-success">
                    Your message was sent, thank you!
                  </div> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Contact;
